.App > .ms-StackItem > * {
  padding: 2rem 10% 2rem;
}

.bg {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #27163e;
  z-index: -1;
  .bg__clouds {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(./../../assets/images/clouds.png);
    background-position: right 170px top 60px;
    background-size: 450px;
    background-repeat: no-repeat;
  }

  .bg__grid {
    position: absolute;
    height: 100%;
    width: 100%;
    background-image: url(./../../assets/images/grid.png);
    background-position: right 30px top 60px;
    background-repeat: no-repeat;
  }
}
